import * as React from 'react'
import NewYear from "../../components/VideoPage/VideoPage";

const NewYear2020 = () => {
  return (
      <NewYear
        url="https://tfproductionmediaservice-euwe.streaming.media.azure.net/e0f2fd28-d3dd-40f8-b5b0-645fd2804a4f/Emso Vimeo _26s 7.ism/manifest"
      />
  )
}

export default NewYear2020;