import Layout from "../../layouts/Layout";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import * as React from "react";

import 'video.js/dist/video-js.min.css';

export default ({url}) => {
    const videoStyle = {
        paddingTop: '150px',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        maxWidth: '1400px'
    }

    return (
        <Layout getInTouchVisible={false} blueFooter={true} login={false} showNavigation={false} isNonCompliantPage={true}>
            <div style={videoStyle}>
                <VideoPlayer
                    src={url}
                    controls
                    fluid
                    useMaxHeightLimit
                    loading
                    autoPlay={true}
                    centerPlayButton={true}
                />
            </div>
        </Layout>
    )
}